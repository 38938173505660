'use strict';

Gri.module({
  name: 'sidebar-alpha',
  ieVersion: null,
  $el: $('.sidebar-alpha'),
  container: '.sidebar-alpha',
  fn: function () {

    const $this = this.$el;

    resizeController();
    $(window).on('resize', resizeController);

    function resizeController() {
      let windowW = $(window).width();

      if(windowW > 767) {
        let headerH = $('.header-alpha').height();
        let thisH = $this.outerHeight();

        $('body').css('margin-top', headerH + thisH);
        $this.css({
          top: headerH
        });
      }
    }

  }
});
